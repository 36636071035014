@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Newsreader:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Coustard:wght@400;900&display=swap');

.header-box{

    padding-top: 24px;
    display:flex;
    justify-content: center;
    flex-direction:row;
    margin-bottom: 10px;
    padding-bottom: 0px;
    border-bottom: 0px;
}
.obcc-logo{
    display:flex;
    height: 2.6em;
    width:3.5em;
    margin-left:1em;
    margin-right:1em;
    margin-top:.4em;
}

.header-text-box{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.header-text-box h2{
    margin-top: 12px;
    margin-bottom: 2px;
}

.header-box h1{
    font-size:3.0rem;
    letter-spacing: 1px;
    font-family: 'Coustard', serif;
    font-weight: 400;

}
h1, h2, h3, h4{
    font-family: 'averia-serif-libre';
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

.celtic-knot{
    margin: 9px;
    height:29px
}
hr{
    border-top: 6px solid #bbb;
    width: 28em;
}
.wtt-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: .4em;
    padding-left: .6em;
    padding-right: .6em;
    padding-bottom: .35em;
}
.wtt-box > div{
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 4px;
    width: 130px;
    text-align: center;
    font-weight: bold;
    font-size: .9em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
    margin-left: 1.35em;
    margin-right:1.35em;
    background-color: #fff;
}
.wtt-box > div:before{
    
}

.utility-icon > *{
    align-self: center;
}
.utility-icon >h4{
    font-size: 1.2em;
}

.utility-icon img{
    height: 65px;
    margin-top:4px;
    margin-bottom: 0.4px;
}
.tide-component{
    padding: 2px;
    width:70%;
}
.lunar-emoji{
    font-size:3.2rem;
    margin-top:4px;
    margin-bottom: 0.4px;
}
.sun-icon img{
    margin: .5em;
    border-radius: 5px;
    padding-right: .6em;
    padding-bottom: .12em;
    padding-top: .12em;
    height: 3.7em;
}
.body{
    display:flex;
    flex-direction:column;
    align-items: center;
    
    
}
.body >*{
    margin-bottom: .35rem;
    margin-top:.35rem;
    max-width: 54em;
}

.box2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: left;   
    flex-grow:1;
}
.box2 >div{
    
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    border: 1px solid #000000;
    margin: .1em;
    border-radius: 5px;
    padding-left: .6em;
    padding-right: .6em;
    padding-bottom: .6em;
    padding-top: .4em;
    max-width: 55em;
    background-image: linear-gradient(to bottom right, #F7F5ED, #DEDBD3);
    min-width: 22.3em;
}

.detailed-forecasts{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;   
    
}
.detailed-forecast{
    border-radius: 5px;
    padding-left: .6em;
    padding-right: .6em;
    padding-bottom: .6em;
    padding-top: .23em;
    margin-bottom: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
    font-size: 1.3rem;
    background-color: #fff;
}
.detailed-forecast h3{
    font-size: 1.2rem;
    letter-spacing: .1rem;
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-top:0px;
    padding-top:0px;
    padding-bottom:0px;
    border-radius: 0%;
}

.detailed-forecast .image-text{
    display:flex;
    flex-direction:row;
}

.detailed-forecast img{
    padding-right: 20px;
    height:112px
}

.radar-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 0px solid #fff;
  border-radius: 5px;
  padding-top: 0px;
  padding-bottom: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
  position: relative;
}
.radar-container {
  position: relative;
  display: inline-block;
  margin-left: 32px;
  margin-right: 32px;
}

.overlay {
  position: absolute;
  bottom: 202px;
  left: 281px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  position: relative;
}

.dot-center {
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.label {
  margin-left: 10px;
  color: red;
  font-weight: bold;
  font-size: 14px;
}

thead {
    padding-top: 11px;
    background-color: #04AA6D;
    border-color: #04AA6D;
    border: 2px;
    color: white;
  }
  th {

    padding: 10px;
    background-color: #585a6b;
    border-color: #04AA6D;
    border: 2px;
    color: white;
  }
  tr{
    text-align: center;
  }


*, *:before, *:after {
  box-sizing: border-box;
}

body {
  font-family: Georgia, 'Times New Roman', Times, serif;
  /**font-family: "Rockwell Extra Bold", "Rockwell", monospace; **/
  margin: 0;
  background: #F5F2E8;
}


.container {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

table {
  width: 100%;
  border: 1px solid #EEEEEE;
}

.table-header {
  display: flex;
  width: 100%;
  background: #000;
  padding: 18px 0;
}

.table-row {
  display: flex;
  width: 100%;
  padding: 18px 0;
}
tbody tr:nth-child(odd) {
    background-color: #92b2c9;
  }
  tbody tr:nth-child(even) {
    background-color: #fff;
  }

.table-data, .header__item {
  flex: 1 1 20%;
  text-align: center;
}

.header__item {
  text-transform: uppercase;
}

.filter__link {
  color: #ffffff;
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding-left: 24px;
  padding-right: 24px;
}
.filter__link::after {
  content: "";
  position: absolute;
  right: -18px;
  color: white;
  font-size: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.filter__link.desc::after {
  content: "(desc)";
}
.filter__link.asc::after {
  content: "(asc)";
}

.ossining-logo{
    width:35px;
    justify-content: center;
    align-items: center;
    align-self: center;
    
}

.footer{  
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 0;
    right: 0;
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    position: relative;
    background: #333333;
    list-style: none;
    color: #fff;
    position: absolute;
    width: 100%;
    height: 6rem;            /* Footer height */
    font: 18px 'Source Sans Pro', sans-serif;
}
.footer p {
    margin:auto;
    width: 49rem;
    text-align: center;
}

@font-face {
    font-family: ancient-medium;
    src: url(/public/Ancient\ Medium.ttf);
}
@font-face {
    font-family: averia-serif-libre;
    src: url(/public/Averia_Serif_Libre/AveriaSerifLibre-BoldItalic.ttf);
}